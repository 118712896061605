import React from "react";
import { Container, Row, Col, Button, Nav, Form, FormControl} from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
import MentalMath from '../images/BC_MathGenius2.PNG';
import { Footer, Navbar } from "../components";
import HeaderMobileApp from './HeaderMobileApp';

const MobileApp = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <Navbar />
     <HeaderMobileApp/>
      <p className="font-caption">Showcase of Mobile App Division..</p>
     <p className="paragraph_text"> Mobile apps help businesses improve efficiency, drive higher engagement, and meet customers' evolving needs, making them a powerful tool in today’s business landscape.</p>    
     <p className="paragraph_text">We are capable of delivering a well-designed mobile application compatable for both Android and IOS</p>    
     <p className="paragraph_text">Math Genius - A Mental Math App is designed and developed by Bairagi Consultants and placed successfully in Google Play Store.</p>    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src={MentalMath} 
      alt="FrontEnd Technology"  
    />  
    </div>

    <p className="font-caption">Front End Technology.</p>
    <p className="paragraph_text">Mobile framework like React Native, Flutter, or Swift for iOS and Kotlin for Android if you want platform-specific apps.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/Android.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
    <p className="font-caption">Back-end Technology.</p>
    <p className="paragraph_text">Secure backend API to handle login and user management are being developed using frameworks like Node.js, Django etc.</p> 
    
     <div  className='d-flex justify-content-center align-items-center p-2'
        style={{ width: '50%', height: '40%' }}>  
      <img  
      className="w-100 h-0"  
      src="./assets/Webapp/Android.PNG" 
      alt="FrontEnd Technology"  
    />  
    </div>
     
     <Footer />
  </>
    
     
  );
};

export default MobileApp;
